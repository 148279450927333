import clientConfig from '../../client-config';
import imageUrlBuilder from '@sanity/image-url';
import {getFluidGatsbyImage, getFixedGatsbyImage} from 'gatsby-source-sanity';

const builder = imageUrlBuilder(clientConfig.sanity)

export function imageUrlFor (source) {
  return builder.image(source)
}

export function getFluidProps(image, maxWidth = 900) {
  return getFluidGatsbyImage(image, { maxWidth }, clientConfig)
}

export function getFixedProps(image, width, height ) {
  return getFixedGatsbyImage(image, { width, height }, clientConfig);
}
